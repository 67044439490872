import { useState } from "react";
import { AirfieldSlotType } from "../../models/airfieldSlots";
import moment from "moment";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import ReadOnlyInputBox from "../../components/InputBox/ReadonlyInputBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputBox from "../../components/InputBox/InputBox";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

interface AirfieldSlotsSelectRestrictionModalComponentProps {
  saving: boolean;
  date: Date;
  saveRestrictionPressed: (
    fromTime: Date,
    toTime: Date,
    remarks: string,
    slotType: AirfieldSlotType,
    edittedRestrictionId?: string
  ) => void;
  cancelPressed: () => void;
  availableSlotTypes: AirfieldSlotType[];
  edittedRestriction?: {
    startDateTimeEpoch: number;
    endDateTimeEpoch: number;
    reason: string;
    editedRestrictionId: string;
  };
}

function AirfieldSlotsSelectRestrictionModalComponent({
  saving,
  date,
  saveRestrictionPressed,
  cancelPressed,
  availableSlotTypes,
  edittedRestriction,
}: AirfieldSlotsSelectRestrictionModalComponentProps) {
  const restrictionDate = date;

  const [restrictionFromTime, setRestrictionFromTime] = useState(
    edittedRestriction
      ? moment(edittedRestriction.startDateTimeEpoch * 1000)
          .utc(false)
          .format("HHmm")
      : ""
  );
  const [restrictionToTime, setRestrictionToTime] = useState(
    edittedRestriction
      ? moment(edittedRestriction.endDateTimeEpoch * 1000)
          .utc(false)
          .format("HHmm")
      : ""
  );
  const [restrictionRemarks, setRestrictionRemarks] = useState(edittedRestriction ? edittedRestriction.reason : "");
  const [restrictionSlotType, setRestrictionSlotType] = useState<AirfieldSlotType>(availableSlotTypes[0]);
  const [fromInvalidError, setFromInvalidError] = useState("");
  const [toInvalidError, setToInvalidError] = useState("");
  const [remarksInvalidError, setRemarksInvalidError] = useState("");

  const checkForTimeErrors = () => {
    var errorFound = false;
    const fromTime = moment(restrictionFromTime, "HHmm", true);
    const toTime = moment(restrictionToTime, "HHmm", true);

    if (!fromTime.isValid()) {
      setFromInvalidError("Invalid time format. Must be in HHmm format");
      errorFound = true;
    } else {
      setFromInvalidError("");
    }

    if (!toTime.isValid()) {
      setToInvalidError("Invalid time format. Must be in HHmm format");
      errorFound = true;
    } else {
      setToInvalidError("");
    }

    if (fromTime.isValid() && toTime.isValid()) {
      if (fromTime.isAfter(toTime)) {
        setFromInvalidError("From time must be before to time");
        setToInvalidError("To time must be after from time");
        errorFound = true;
      }
    }

    return errorFound;
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Add Restriction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="gy-2 gx-2">
          <Col xs={12}>
            <ReadOnlyInputBox name={"Date"} isDisabled={saving} value={moment(restrictionDate).utc(false).format("DD/MM/YYYY")} />
          </Col>
          <Col xs={12}>
            <div className={"input-container rounded-lg"}>
              <Dropdown
                onSelect={(newValue) => {
                  setRestrictionSlotType(newValue as AirfieldSlotType);
                }}
              >
                <Dropdown.Toggle
                  as={"input"}
                  readOnly
                  disabled={saving}
                  className="bg-transparent w-100"
                  value={restrictionSlotType}
                  style={{ cursor: saving ? "auto" : "pointer" }}
                />
                <Dropdown.Menu>
                  {availableSlotTypes.map((value) => {
                    return (
                      <Dropdown.Item key={value} eventKey={value} active={value === restrictionSlotType}>
                        {value}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <label className={restrictionSlotType && "filled"}>{"Slot"}</label>
              <FontAwesomeIcon
                className="position-absolute "
                style={{ right: "16px", top: "50%", transform: "translateY(-50%)" }}
                icon={faCaretDown}
              />
            </div>
          </Col>
          <Col xs={12}>
            <InputBox
              value={restrictionFromTime}
              name={"From Time (UTC) - Format: HHmm"}
              isDisabled={saving}
              type={"text"}
              onChange={(v) => {
                setFromInvalidError("");
                setRestrictionFromTime(v);
              }}
              invalidError={fromInvalidError}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={restrictionToTime}
              name={"To Time (UTC) - Format: HHmm"}
              isDisabled={saving}
              type={"text"}
              onChange={(v) => {
                setToInvalidError("");
                setRestrictionToTime(v);
              }}
              invalidError={toInvalidError}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={restrictionRemarks}
              name={"Remarks"}
              isDisabled={saving}
              type={"text"}
              onChange={(v) => {
                setRestrictionRemarks(v);
              }}
              invalidError={remarksInvalidError}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={saving} variant="light" onClick={cancelPressed}>
          Cancel
        </Button>
        <Button
          disabled={saving}
          onClick={() => {
            var errorFound = checkForTimeErrors();
            if (restrictionRemarks === "") {
              setRemarksInvalidError("Remarks cannot be empty");
              errorFound = true;
            }
            if (!errorFound) {
              saveRestrictionPressed(
                moment(restrictionDate)
                  .utc(false)
                  .startOf("day")
                  .add(parseInt(restrictionFromTime.slice(0, 2)), "hours")
                  .add(parseInt(restrictionFromTime.slice(2, 4)), "minutes")
                  .toDate(),
                moment(restrictionDate)
                  .utc(false)
                  .startOf("day")
                  .add(parseInt(restrictionToTime.slice(0, 2)), "hours")
                  .add(parseInt(restrictionToTime.slice(2, 4)), "minutes")
                  .toDate(),
                restrictionRemarks,
                restrictionSlotType,
                edittedRestriction?.editedRestrictionId
              );
            }
          }}
        >
          {edittedRestriction ? "Edit Restriction" : "Add Restriction"}
        </Button>
      </Modal.Footer>
    </>
  );
}

export default AirfieldSlotsSelectRestrictionModalComponent;
