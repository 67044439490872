import { useMediaQuery } from "usehooks-ts";
import { AirfieldSlotType } from "../../models/airfieldSlots";
import { useEffect } from "react";
import { Button } from "react-bootstrap";

interface AirfieldSlotsSelectFilterButtonsComponentProps {
    filteredSlotType: AirfieldSlotType | undefined;
    setFilteredSlotType: React.Dispatch<React.SetStateAction<AirfieldSlotType | undefined>>;
    availableSlots: {name: string, slotType: AirfieldSlotType}[]
  }
  
  function AirfieldSlotsSelectFilterButtonsComponent({filteredSlotType, setFilteredSlotType, availableSlots}: AirfieldSlotsSelectFilterButtonsComponentProps) {
    const isSmallerThanXll = useMediaQuery("(max-width: 1400px)");
    useEffect(() => {
      if (isSmallerThanXll) {
        setFilteredSlotType(availableSlots[0].slotType);
      } else {
        setFilteredSlotType(undefined);
      }
    }, [isSmallerThanXll, setFilteredSlotType, availableSlots]);
  
  
    return (
      <>
        {availableSlots.length === 1 ? (
          <></>
        ) : (
          <div className="d-flex pb-2">
            {!isSmallerThanXll && (
              <Button
                variant="dark"
                disabled={filteredSlotType === undefined}
                className="me-2"
                onClick={() => {
                  setFilteredSlotType(undefined);
                }}
              >
                All
              </Button>
            )}
  
            {availableSlots.map((slot) => (
              <Button
                className="me-2"
                disabled={filteredSlotType === slot.slotType}
                variant="dark"
                onClick={() => {
                  setFilteredSlotType(slot.slotType);
                }}
              >
                {slot.name}
              </Button>
            ))}
          </div>
        )}
      </>
    );
  }

export default AirfieldSlotsSelectFilterButtonsComponent