import { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Offcanvas, Row } from "react-bootstrap"
import { Link, matchPath, NavLink, Outlet, useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectedOrganization, getOrganizations, createAnonymousOrganization } from "../../store/organizationsSlice";
import SideMenuOrganizationComponent from "./SideMenuOrganizationComponent"
import "./SideMenuLayout.css"
import LoadingComponent from "../../components/LoadingComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCaretRight, faBars } from "@fortawesome/free-solid-svg-icons";
import { Organization } from "../../models/organization";
import { auth } from "../../firebase";
import SelectOrganizationModal from "../../pages/organization/selectOrganization/SelectOrganizationModal";

function SideMenuLayout(){
  const selectedOrg = useAppSelector(selectedOrganization);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams()

  const [showMobileOffcanvas, setShowMobileOffcanvas] = useState(false)
  const [showSelectOrganizationModal, setShowSelectOrganizationModal] = useState(false)

  const {allOrganizations: allOrgs, userHasNoOrganizations} = useAppSelector((state) => state.organizations)

  const hideSideMenu = useMemo(() => {
    return selectedOrg?.showOnlyAirfieldId !== undefined;
  }, [selectedOrg]);

  const airfieldSlots = useMemo(() => {
    return selectedOrg?.airfieldSlots ?? [];
  }, [selectedOrg]);

  const navigate = useNavigate()

  useEffect(() => {
    if (allOrgs.length === 0 ){
      if (auth.currentUser?.isAnonymous){
        const isInAirfieldSlots = matchPath(`/airfields/:airfieldId/slots`, window.location.pathname);
        if (isInAirfieldSlots){
          dispatch(createAnonymousOrganization({uid: auth.currentUser!.uid, airfieldId: isInAirfieldSlots.params.airfieldId!}))
          return
        }
      } 
      dispatch(getOrganizations())
    }
  }, [selectedOrg, allOrgs, dispatch]);

  useEffect(() => {
    if (searchParams.has("redirectTo")) {
      navigate(searchParams.get("redirectTo")!);
    } else if (selectedOrg?.showOnlyAirfieldId) {
      const isInAirfieldSlots = matchPath(`/airfields/:airfieldId/slots`, window.location.pathname);
      if (!isInAirfieldSlots || isInAirfieldSlots.params.airfieldId !== selectedOrg.showOnlyAirfieldId) {
        navigate(`/airfields/${selectedOrg.showOnlyAirfieldId}/slots`);
      }
    } else if (selectedOrg) {
       if (selectedOrg.role === "pilot") {
        navigate("/fly");
      } else if (window.location.pathname === "/") {
        navigate("/flights");
      }
    } else if (userHasNoOrganizations) {
      navigate("/organization/create");
    }
  }, [navigate, searchParams, selectedOrg, userHasNoOrganizations, dispatch]);

  return (
    <>
      {selectedOrg && selectedOrg.role === "admin" ? (
        <>
          {!hideSideMenu && (
            <div className="fixed-top d-md-none container-fluid py-2">
              <div className="d-flex justify-content-end">
                <Button className="" variant="outline-dark" onClick={() => setShowMobileOffcanvas(true)}>
                  <FontAwesomeIcon icon={faBars} color="black" size={"lg"} />
                </Button>
              </div>
            </div>
          )}

          <Container fluid>
            <Row className="vh-100 h-100 d-flex">
              {!hideSideMenu && (
                <Col xs={5} md={3} xxl={2} className="min-vh-100 d-none d-md-block" style={{ backgroundColor: "#F7F7F7" }}>
                  <Row className="h-100">
                    <Col xs={12} className="d-flex flex-column justify-content-between">
                      <div>
                        <Link to="/">
                          <div style={{ paddingTop: "0px" }}>
                            <svg width="185px" height="80px">
                              <image href="/assets/brief/brief_logo.svg" width="100%" />
                            </svg>
                          </div>
                        </Link>
                        <div style={{ paddingTop: "30px" }}>
                          <SideMenuOrganizationComponent presentSelectModal={() => setShowSelectOrganizationModal(true)} />
                          <NavLinksMenu airfieldSlots={airfieldSlots} />
                        </div>
                      </div>
                      <AccountComponent selectedOrg={selectedOrg} />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col className="bg-white shadow-lg overflow-hidden h-100">
                <Outlet />
              </Col>
            </Row>
          </Container>
          <Offcanvas show={showMobileOffcanvas} placement="end">
            <Row className="h-100 px-2 bg-light">
              <Col xs={12} className="d-flex flex-column justify-content-between">
                <div>
                  <div style={{ paddingTop: "30px" }}>
                    <SideMenuOrganizationComponent
                      presentSelectModal={() => {
                        setShowMobileOffcanvas(false);
                        setShowSelectOrganizationModal(true);
                      }}
                    />

                    <NavLinksMenu setShowMobileOffcanvas={setShowMobileOffcanvas} airfieldSlots={airfieldSlots}/>
                  </div>
                </div>
                <AccountComponent selectedOrg={selectedOrg} setShowMobileOffcanvas={setShowMobileOffcanvas} />
              </Col>
            </Row>
          </Offcanvas>
          {showSelectOrganizationModal && <SelectOrganizationModal hideModal={() => setShowSelectOrganizationModal(false)} />}
        </>
      ) : (
        <LoadingComponent text={"Loading your organizations..."} />
      )}
    </>
  );
}

export default SideMenuLayout

interface AccountComponentProps {
  selectedOrg: Organization;
  setShowMobileOffcanvas?: React.Dispatch<React.SetStateAction<boolean>>;
}

function AccountComponent({ selectedOrg, setShowMobileOffcanvas }: AccountComponentProps) {
  const navigate = useNavigate();

  const goToAccountPressed = () => {
    if (setShowMobileOffcanvas) {
      setShowMobileOffcanvas(false);
    }
    navigate("/account");
  }

  return (
    <div>
      <div className="pb-3">
        <div className="d-grid d-md-none pb-3">
          <Button variant="outline-dark" onClick={() => navigate("/fly")}>
            Go to Fly
          </Button>
        </div>
        <div className="bg-white border rounded h-100 w-100"  onClick={goToAccountPressed} style={{ cursor: "pointer" }}>
          <div className="d-flex align-items-center my-1">
            <div className="px-2 ">
              <FontAwesomeIcon icon={faUser} color="black" size={"lg"} />
            </div>
            <div className="overflow-hidden flex-fill">
              <div className="fw-medium">{auth.currentUser?.email}</div>
              <div className="fw-light fs-6">
                <small>
                  Credits: <strong>{selectedOrg.billing.type === "prepaid" ? selectedOrg.billing.credits : "Pay as you go"}</strong>
                </small>
              </div>
            </div>
            <div className="ms-1 me-2">
              <FontAwesomeIcon icon={faCaretRight} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface NavLinksMenuProps {
  setShowMobileOffcanvas?: React.Dispatch<React.SetStateAction<boolean>>;
  airfieldSlots: Organization["airfieldSlots"];
}

function NavLinksMenu({ setShowMobileOffcanvas, airfieldSlots }: NavLinksMenuProps) {
  return (
    <div className="sticky-top">
      <nav className="nav flex-column nav-pills pt-4">
        <NavLink to="/flights" className="nav-link" onClick={() => setShowMobileOffcanvas && setShowMobileOffcanvas(false)}>
          Flights
        </NavLink>
        <NavLink to="/fpls" className="nav-link" onClick={() => setShowMobileOffcanvas && setShowMobileOffcanvas(false)}>
          Quick FPLs
        </NavLink>
        <NavLink to="/routes" className="nav-link" onClick={() => setShowMobileOffcanvas && setShowMobileOffcanvas(false)}>
          Routes
        </NavLink>
        <NavLink to="/aircraft" className="nav-link" onClick={() => setShowMobileOffcanvas && setShowMobileOffcanvas(false)}>
          Aircraft
        </NavLink>
        <NavLink to="/crew" className="nav-link" onClick={() => setShowMobileOffcanvas && setShowMobileOffcanvas(false)}>
          Crew
        </NavLink>
        <NavLink to="/passengers" className="nav-link" onClick={() => setShowMobileOffcanvas && setShowMobileOffcanvas(false)}>
          Passengers
        </NavLink>
          <NavLink to="/airfields" className="nav-link" onClick={() => setShowMobileOffcanvas && setShowMobileOffcanvas(false)} >
            Airfields (beta)
          </NavLink>
          {/* //show airfields in the list as favorite */}
      </nav>
    </div>
  );
}