import { createSlice } from "@reduxjs/toolkit";
import { AviTracerApi } from "../avitracerApi";
import { createAppAsyncThunk } from "./hooks";
import { Airfield } from "../models/airfieldSlots";

interface AirfieldsState {
    airfields: Airfield[];
    status: "idle" | "success"
    loading: boolean
}

const initialState: AirfieldsState = {
    airfields: [],
    status: "idle",
    loading: false
}

export const getAirfields = createAppAsyncThunk(
    "airfields/fetch",
    async () => {
        return AviTracerApi.getAirfields()
    }
)

export const airfieldsSlice = createSlice({
    name: "airfields",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getAirfields.fulfilled, (state, action) => {
            state.airfields = action.payload
            state.status = "success"
            state.loading = false
        })
        builder.addCase(getAirfields.pending, (state) => {
            state.loading = true
        })
    }
})

export default airfieldsSlice.reducer