import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { getAirfields } from "../../store/airfieldsSlice";
import { Col, Container, Row } from "react-bootstrap";
import ListEntitiesHeaderComponent from "../../components/ListEntities/ListEntitiesHeaderComponent";
import LoadingComponent from "../../components/LoadingComponent";
import { Airfield } from "../../models/airfieldSlots";
import { createColumnHelper } from "@tanstack/react-table";
import ListEntitiesTable from "../../components/ListEntities/ListEntitiesTable";


function ListAirfieldsPage() {
  const dispatch = useAppDispatch();

  const { airfields, status, loading } = useAppSelector((state) => state.airfields);


  useEffect(() => {
    if (status === "idle"){
      dispatch(getAirfields())
    }

  },[dispatch, status])
  

  return (
    <Container fluid className="vh-100 px-0 px-md-2">
      <ListEntitiesHeaderComponent
        title="Aifields"
      />

      {loading ? (
        <LoadingComponent text="Loading airfields..." />
      ) : (
        <Row className="overflow-auto" style={{ height: "calc(100% - 140px)" }}>
          <Col>
            <AirfieldsTable data={airfields} />
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default ListAirfieldsPage;

interface AirfieldsTableProps{
  data: Airfield[]
}

function AirfieldsTable({ data }: AirfieldsTableProps) {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<Airfield>();

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Airfield Name",
      cell: (info) => info.getValue(),
      sortingFn:"alphanumeric"
    })
  ];

  return (
    <ListEntitiesTable
      data={data}
      columns={columns}
      onRowClick={(airfield) => {
        navigate(`/airfields/${airfield.id}/slots`)
      }}
    />
  );
}