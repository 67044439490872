import { ControllerFieldState, Noop, RefCallBack } from "react-hook-form";

interface TextAreaBoxProps {
  isDisabled: boolean;
  value: string;
  onChange: (newValue: string) => void;
  name: string;
  uppercase?: boolean
  fieldState?: ControllerFieldState
  invalidError?: string
  field?: {
    onBlur: Noop;
    ref: RefCallBack
  }
  rows?: number
}

function TextAreaBox({ isDisabled, value, onChange, name, fieldState, invalidError, field, uppercase, rows = 4}: TextAreaBoxProps) {

  const {error, invalid} = fieldState ?? (invalidError ? {error: {message: invalidError}, invalid: true} : {error: undefined, invalid: false})

  return (
    <>
      <div className={(invalid ? "invalid " : "") + "input-container rounded-lg "}>
        <textarea
          rows={rows}
          minLength={2}
          disabled={isDisabled}
          className="bg-transparent"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onInput={(e) => {
            if (uppercase) {
              const start = e.currentTarget.selectionStart;
              const end = e.currentTarget.selectionEnd;
              e.currentTarget.value = ("" + e.currentTarget.value).toUpperCase();
              e.currentTarget.setSelectionRange(start, end);
            }
          }}
          onBlur={field?.onBlur}
          ref={field?.ref}
        />
        <div
          style={{
            backgroundColor: "#F7F7F7",
            height: "28px",
            width: "100%",
            position: "absolute",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          }}
        ></div>
        <label className={value && "filled"}>{name}</label>
      </div>
      {error && <small className="text-danger">{error.message}</small>}
    </>
  );
}

export default TextAreaBox;