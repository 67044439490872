import { signOut } from "firebase/auth";
import { useEffect } from "react";
import { auth } from "../../firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingComponent from "../../components/LoadingComponent";

function Logout(){
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()

    useEffect(() => {
      const signout = async () => {
        await signOut(auth);

        const redirectToParam = (searchParams.has("redirectTo") ? `?redirectTo=${searchParams.get("redirectTo")}` : "");
        if (searchParams.has("goToPath")) {
            navigate(searchParams.get("goToPath")! + redirectToParam);
        } else {
          navigate("/auth/login" + redirectToParam);
        }
      };
      signout();
    }, [navigate, searchParams]);

    return (
        <LoadingComponent text="Logging out..."/>
    )
}

export default Logout;
