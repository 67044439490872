import { configureStore } from '@reduxjs/toolkit'
import organizationsReducer, { clearStoreAfterOrganizationChange, refreshOrganizationCredits } from './organizationsSlice'
import passengersReducer from './passengersSlice'
import crewReducer from './crewSlice'
import aircraftReducer from './aircraftSlice'
import routesReducer from './routesSlice'
import flightsReducer from './flightSlice'
import flightPlansReducer from './flightPlansSlice'
import airfieldsReducer from './airfieldsSlice'

export const store = configureStore({
  reducer: {
    organizations: organizationsReducer,
    passengers: passengersReducer,
    crew: crewReducer,
    aircraft: aircraftReducer,
    routes: routesReducer,
    flights: flightsReducer,
    flightPlans: flightPlansReducer,
    airfields: airfieldsReducer,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware().concat([clearStoreAfterOrganizationChange.middleware, refreshOrganizationCredits.middleware])
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch