import { Cell, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import {  Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import ReadOnlyInputBox from "../../components/InputBox/ReadonlyInputBox";
import { useCallback, useEffect, useMemo, useState } from "react";
import TextAreaBox from "../../components/InputBox/TextAreaBox";
import InputBox from "../../components/InputBox/InputBox";
import { DatabaseReference, OnDisconnect, onDisconnect, onValue, push, ref, set } from "firebase/database";
import { auth, database } from "../../firebase";
import { LoaderFunction, useLoaderData, useMatch, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AviTracerApi } from "../../avitracerApi";
import { useAppSelector } from "../../store/hooks";
import { selectedOrganization } from "../../store/organizationsSlice";
import { Airfield, AirfieldSlot, AirfieldSlotContactDetails, AirfieldSlotStored, AirfieldSlotTime, AirfieldSlotType } from "../../models/airfieldSlots";
import FlatPicker from 'react-flatpickr'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays} from '@fortawesome/free-solid-svg-icons'
import ListEntitiesHeaderComponent from "../../components/ListEntities/ListEntitiesHeaderComponent";
import LoadingComponent from "../../components/LoadingComponent";
import useCurrentTime from "../../utils/useCurrentTime";
import AirfieldSlotsSelectCell from "./AirfieldSlotsSelectCell";
import AirfieldSlotsSelectFilterButtonsComponent from "./AirfieldSlotsSelectFilterButtonsComponent";
import AirfieldSlotsSelectRestrictionModalComponent from "./AirfieldSlotsSelectRestrictionModalComponent";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { isAnonymousOrganization } from "../../models/organization";

export const airfieldSlotTimesLoader: LoaderFunction = async (params) => {
  const airfieldId = params.params["airfieldId"]!.toLowerCase();

  const url = new URL(params.request.url);
  var dateStr: string = moment().startOf("day").utc(true).format("DD-MM-YYYY");
  if (url.searchParams.has("date")) {
    dateStr = url.searchParams.get("date")!
  }

  if (!auth.currentUser) {
    const checkUserProm = new Promise<void>((resolve, reject) => {
      const unsub = onAuthStateChanged(auth, async (user) => {
        if (user) {
          resolve();
        } else {
          await signInAnonymously(auth);
          resolve();
        }
        unsub();
      });
    });
    await checkUserProm;
  }
  return await AviTracerApi.getAirfieldSlotTimesInfoForDate(airfieldId, dateStr);
}


function AirfieldSlotsSelectPage() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTime = useCurrentTime();

  const selectedOrg = useAppSelector(selectedOrganization);

  const { slotTimes: loadedSlotTimes, airfield } = useLoaderData() as {slotTimes: AirfieldSlotTime[], airfield: Airfield;};
  const { slotsRestrictions, adminOrgId, slotsNeedApproval, timeTypesToLog, availableSlots, slotsArePrivate} = airfield;

  const availableSlotTypes = useMemo(() => {
    return (availableSlots ?? []).map((s) => s.slotType);
  }, [availableSlots]);

  const airfieldId = params["airfieldId"]!.toLowerCase();
  const dateStr = searchParams.has("date") ? searchParams.get("date") : moment().utc(true).format("DD-MM-YYYY");
  const date = useMemo(() => {
    return moment(dateStr, "DD-MM-YYYY").utc(true).toDate();
  }, [dateStr]);

  const isAdminOrg = adminOrgId === selectedOrg?.id

  const slotDatePath = useMemo(() => {
    return `/airfield/${airfieldId}/slots/${dateStr}`;
  }, [dateStr, airfieldId]);

  const [storedSlots, setStoredSlots] = useState<AirfieldSlotStored[]>([]);
  const [hasLoadedStoredSlots, setHasLoadedStoredSlots] = useState(false);

  const [defaultContactDetails, setDefaultContactDetails] = useState<AirfieldSlotContactDetails | undefined>();

  useEffect(() => {
    if (selectedOrg && selectedOrg.id === adminOrgId) {
        setDefaultContactDetails({
          email: "",
          fullName: "",
          phone: "",
          orgName: "",
        });
    }else if (selectedOrg && !isAnonymousOrganization(selectedOrg)){
      setDefaultContactDetails({
        email: "",
        fullName: ""  ,
        phone: "",
        orgName: selectedOrg.name,
      });
    }
  }, [selectedOrg, adminOrgId]);

  useEffect(() => {
    setHasLoadedStoredSlots(false);
    const query = ref(database, slotDatePath);
    return onValue(
      query,
      (snapshot) => {
        //add loading
        if (snapshot.exists()) {
          const data: AirfieldSlotStored[] = Object.entries(snapshot.val()).map(([key, value]) => {
            (value as AirfieldSlotStored).id = key;
            return value as AirfieldSlotStored;
          });

          setStoredSlots(data);
        } else {
          setStoredSlots([]);
        }
        setHasLoadedStoredSlots(true);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [airfieldId, date, selectedOrg?.airfieldSlots, slotDatePath]);

  const [slotTimes, setSlotTimes] = useState<AirfieldSlotTime[]>([]);

  useEffect(() => {
    if (!hasLoadedStoredSlots) {
      return;
    }
    var tempSlotTimes: AirfieldSlotTime[] = JSON.parse(JSON.stringify(loadedSlotTimes)); //create deep copy
    tempSlotTimes.forEach((st) => {
      //set again startDateTime that is lost at JSON.parse
      st.startDateTime = moment(st.startDateTimeEpoch * 1000).utc(false).toDate();
    });

    storedSlots.forEach((storedSlot) => {
      if (storedSlot.status === "unavailable") {
        const affectedSlotTimes = tempSlotTimes.filter(
          (s) =>
            s.startDateTime.getTime() >= storedSlot.startDateTimeEpoch * 1000 &&
            s.startDateTime.getTime() <= storedSlot.endDateTimeEpoch * 1000
        );
        affectedSlotTimes.forEach((s) => {
          var slot = s.slots[storedSlot.slotType];
          slot.available = false;
          slot.unavailableReason = storedSlot.reason;
          slot.unavailableSlotId = storedSlot.id;
        });
      } else {
        const slotTime = tempSlotTimes.find(
          (tempSlotTime) => tempSlotTime.startDateTime.getTime() === storedSlot.startDateTimeEpoch * 1000
        );
        if (slotTime) {
          if (storedSlot.status === "booked") {
            slotTime.slots[storedSlot.slotType].bookedBy = {
              uid: storedSlot.bookedBy.uid,
              orgId: storedSlot.bookedBy.orgId,
              orgName: storedSlot.bookedBy.orgName,
              callsign: storedSlot.bookedBy.callsign,
              flightId: storedSlot.bookedBy.flightId,
              bookedSlotId: storedSlot.id,
              remarks: storedSlot.remarks,
              contactDetails: storedSlot.contactDetails,
            };
            slotTime.slots[storedSlot.slotType].blocksOffEpoch = storedSlot.blocksOffEpoch;
            slotTime.slots[storedSlot.slotType].takeoffEpoch = storedSlot.takeoffEpoch;
            slotTime.slots[storedSlot.slotType].landedEpoch = storedSlot.landedEpoch;
          }else if (storedSlot.status === "requested" && storedSlot.requestedBy.orgId === selectedOrg?.id! && !isAdminOrg){
              slotTime.slots[storedSlot.slotType].selectedOrgRequest = {
                requestedSlotId: storedSlot.id,
                callsign: storedSlot.requestedBy.callsign,
                remarks: storedSlot.remarks,
                contactDetails: storedSlot.contactDetails,
              }
          } else if (storedSlot.status === "requested" && isAdminOrg){ 
            slotTime.slots[storedSlot.slotType].allRequests = [
              ...slotTime.slots[storedSlot.slotType].allRequests ?? [],
              {
                requestedSlotId: storedSlot.id,
                orgId: storedSlot.requestedBy.orgId,
                orgName: storedSlot.requestedBy.orgName,
                callsign: storedSlot.requestedBy.callsign,
                remarks: storedSlot.remarks,
                contactDetails: storedSlot.contactDetails,
              },
            ];
          }else if (
            !slotsNeedApproval &&
            storedSlot.status === "pending_confirmation" &&
            storedSlot.untilDateTimeEpoch * 1000 > moment(currentTime).toDate().getTime()
          ) {
            slotTime.slots[storedSlot.slotType].pendingConfirmation = {
              untilDateTime: moment(storedSlot.untilDateTimeEpoch * 1000).toDate(),
              by: {
                uid: storedSlot.by.uid,
                orgId: storedSlot.by.orgId,
                orgName: storedSlot.by.orgName,
              },
            };
          }
        }
      }
    });

    if (!isAdminOrg) {
      //create on holds
      tempSlotTimes.forEach((st) => {
        (Object.entries(st.slots) as ["outbound" | "local" | "private" | "parking_1" | "parking_2" | "parking_3" | "parking_4" | "parking_5" , AirfieldSlot][]).forEach(([slotType, slot]) => {
          if (
            !slotsNeedApproval &&
            slotType !== "private" &&
            ((slot.bookedBy && slot.bookedBy!.orgId === selectedOrg!.id) ||
              (slot.pendingConfirmation && slot.pendingConfirmation.by.orgId === selectedOrg!.id))
          ) {
            const startDateTime = moment(st.startDateTime).subtract(slotsRestrictions.onHold.before, "minutes").toDate();
            const endDateTime = moment(st.startDateTime).add(slotsRestrictions.onHold.after, "minutes").toDate();
            const affectedSlotTimes = tempSlotTimes.filter(
              (s) =>
                s.startDateTime.getTime() >= startDateTime.getTime() &&
                s.startDateTime.getTime() <= endDateTime.getTime() &&
                s.startDateTime.getTime() >=
                  moment(currentTime).add(slotsRestrictions.onHold.alwaysAvailableBeforeNow, "minutes").toDate().getTime()
            );
            affectedSlotTimes.forEach((s) => {
              const slot = s.slots[slotType];
              if (slot.available && !slot.onHoldUntil && !slot.bookedBy && !slot.pendingConfirmation) {
                slot.onHoldUntil = moment(s.startDateTime).subtract(slotsRestrictions.onHold.alwaysAvailableBeforeNow, "minutes").toDate();
              }
            });
          } else if (
            slot.available &&
            !slot.onHoldUntil &&
            !slot.bookedBy &&
            !slot.pendingConfirmation &&
            st.startDateTime.getTime() <= moment(currentTime).subtract(slotsRestrictions.unavailableAfterNow, "minutes").toDate().getTime()
          ) {
            slot.available = false;
          }
        });
      });

      //if have already booked 8 slots, then all othher slots are onHoldUntil 06:10
      const bookedSlots = tempSlotTimes
        .map((st) => Object.values(st.slots))
        .flat()
        .filter((s) => s.bookedBy && s.bookedBy!.orgId === selectedOrg!.id);
      const untilDate = moment(date)
        .utc(false)
        .startOf("day")
        .add(slotsRestrictions.maxSlotsPerDay.until.hours, "hours")
        .add(slotsRestrictions.maxSlotsPerDay.until.minutes, "minutes")
        .toDate();

      if (!slotsNeedApproval && bookedSlots.length >= slotsRestrictions.maxSlotsPerDay.numOfSlots && currentTime.getTime() < untilDate.getTime()) {
        tempSlotTimes.forEach((s) => {
          Object.entries(s.slots).forEach(([key, slot]) => {
            if (slot.available && !slot.onHoldUntil && !slot.bookedBy && !slot.pendingConfirmation) {
              slot.onHoldUntil = untilDate;
            }
          });
        });
      }
    }
    setSlotTimes(tempSlotTimes);
  }, [currentTime, date, hasLoadedStoredSlots, loadedSlotTimes, selectedOrg, slotsRestrictions.maxSlotsPerDay.numOfSlots, slotsRestrictions.maxSlotsPerDay.until.hours, slotsRestrictions.maxSlotsPerDay.until.minutes, slotsRestrictions.onHold.after, slotsRestrictions.onHold.alwaysAvailableBeforeNow, slotsRestrictions.onHold.before, slotsRestrictions.unavailableAfterNow, storedSlots, isAdminOrg, slotsNeedApproval]);

  const [bookSlotModalData, setBookSlotModalData] = useState<BookSlotModalData | undefined>(undefined);
  const [savedSlotModalData, setSavedSlotModalData] = useState<{bookSlotModalData: BookSlotModalData, savedSlotData: {callsign: string, remarks: string, contactDetails: AirfieldSlotContactDetails}} | undefined>(undefined);
  const [deleteSlotModalData, setDeleteSlotModalData] = useState<BookSlotModalData | undefined>(undefined);
  const [rejectSlotModalData, setRejectSlotModalData] = useState<{requestedSlotId: string} | undefined>(undefined);
  const [tempPendingConfirmationSlotPath, setTempPendingConfirmationSlotPath] = useState<DatabaseReference | undefined>(undefined);
  const [tempPendingConfirmationDisconnectRef, setTempPendingConfirmationDisconnectRef] = useState<OnDisconnect | undefined>();
  const [saving, setSaving] = useState(false);

  const createPendingConfirmationSlot = useCallback(
    (slotTime: AirfieldSlotTime, slotType: AirfieldSlotType) => {
      const newRef = push(ref(database, slotDatePath));
      setTempPendingConfirmationSlotPath(newRef);
      set(newRef, {
        startDateTimeEpoch: slotTime.startDateTime.getTime() / 1000,
        slotType: slotType,
        status: "pending_confirmation",
        untilDateTimeEpoch: Math.round(moment().add(5, "minutes").toDate().getTime() / 1000),
        by: {
          uid: auth.currentUser!.uid,
          orgName: selectedOrg!.name,
          orgId: selectedOrg!.id,
        },
      } as AirfieldSlotStored);
      return newRef;
    },
    [selectedOrg, slotDatePath]
  );

  useEffect(() => {
    if (bookSlotModalData && !tempPendingConfirmationSlotPath) {
      const newRef = createPendingConfirmationSlot(bookSlotModalData.slotTime, bookSlotModalData.slotType);

      const disconnectRef = onDisconnect(newRef);
      disconnectRef.remove();
      setTempPendingConfirmationDisconnectRef(disconnectRef);
    } else if (!bookSlotModalData && tempPendingConfirmationSlotPath) {
      if (tempPendingConfirmationSlotPath) {
        set(tempPendingConfirmationSlotPath, null);
        tempPendingConfirmationDisconnectRef?.cancel();

        setTempPendingConfirmationSlotPath(undefined);
        setTempPendingConfirmationDisconnectRef(undefined);
      }
    }
  }, [bookSlotModalData, createPendingConfirmationSlot, selectedOrg, slotDatePath, tempPendingConfirmationDisconnectRef,tempPendingConfirmationSlotPath]);

  const acceptRequestedSlotPressed = (requestedSlotId: string) => {
    const acceptAsync = async () => {
      setSaving(true);
      try {
        await AviTracerApi.acceptRequestedAirfieldSlot(airfieldId, dateStr!, requestedSlotId);
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    };
    acceptAsync();
  }

  const rejectRequestedSlotPressed = (requestedSlotId: string) => {
    const rejectAsync = async () => {
      setSaving(true);
      try {
        await AviTracerApi.rejectRequestedAirfieldSlot(airfieldId, dateStr!, requestedSlotId);
        setRejectSlotModalData(undefined);
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    };
    rejectAsync();
  }

  const [filteredSlotType, setFilteredSlotType] = useState<AirfieldSlotType | undefined>(undefined);

  const columnHelper = createColumnHelper<AirfieldSlotTime>();
  var columns: any = [
    columnHelper.accessor("startDateTime", {
      header: "Time (UTC)",
      size: 7,
      cell: (info) => (
        <div className="d-flex align-items-center" style={{height: "120px"}}>
          <div className="fs-1 fw-bold">{moment(info.cell.getValue()).utc(false).format("HH:mm")}</div>
        </div>
      ),
    })
  ]

  availableSlotTypes.forEach((slotType) => {
    if (!filteredSlotType || filteredSlotType === slotType) {
      columns.push(
        columnHelper.accessor(`slots.${slotType}`, {
          header: availableSlots.find((s) => s.slotType === slotType)?.name,
          size: 29,
          cell: (info) => (
            <AirfieldSlotsSelectCell
              airfield={airfield}
              selectedOrg={selectedOrg!}
              isAdminOrg={isAdminOrg}
              info={info}
              setTimeForBookedSlotPressed={setTimeForBookedSlotPressed}
              setDeleteSlotModalData={setDeleteSlotModalData}
              setBookSlotModalData={setBookSlotModalData}
              setSavedSlotModalData={setSavedSlotModalData}
              editRestrictionPressed={editRestrictionPressed}
              slotType={slotType}
              slotName={availableSlots.find((s) => s.slotType === slotType)!.name}
              slotsNeedApproval={slotsNeedApproval}
              slotsArePrivate={slotsArePrivate}
              bookRequestedSlotPressed={acceptRequestedSlotPressed}
              setRejectSlotModalData={setRejectSlotModalData}
              timeTypesToLog={timeTypesToLog}
            />
          ),
        })
      );
    }
  
  })

  const table = useReactTable({
    data: slotTimes,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const bookSlotPressed = (callsign: string, remarks: string, contactDetails: AirfieldSlotContactDetails) => {
    const bookAsync = async () => {
      setSaving(true);
      try {
        await AviTracerApi.bookAirfieldSlot(airfieldId, dateStr!, tempPendingConfirmationSlotPath!.key!, callsign, remarks, contactDetails);
        tempPendingConfirmationDisconnectRef?.cancel();

        setTempPendingConfirmationSlotPath(undefined);
        setTempPendingConfirmationDisconnectRef(undefined);
        setBookSlotModalData(undefined);
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    };
    bookAsync();
  };

  const deleteSlotPressed = (bookedSlotId: string) => {
    const deleteAsync = async () => {
      setSaving(true);
      try {
        await AviTracerApi.cancelBookedAirfieldSlot(airfieldId, dateStr!, bookedSlotId);
        setDeleteSlotModalData(undefined);
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    };
    deleteAsync();
  };

  const setTimeForBookedSlotPressed = (bookedSlotId: string, timeType: "blocksOff" | "takeoff" | "landed") => {
    const setTimeAsync = async () => {
      await AviTracerApi.setTimeInAirfieldBookedSlot(airfieldId, dateStr!, bookedSlotId, timeType)
    }
    setTimeAsync()
  }

  const [addRestrictionModalData, setAddRestrictionModalData] = useState<{edittedRestriction?: {
    startDateTimeEpoch: number
    endDateTimeEpoch: number
    reason: string
    editedRestrictionId: string
  }} | undefined>(undefined);

  const saveRestrictionPressed = (fromTime: Date, toTime: Date, remarks: string, slotType: AirfieldSlotType,  edittedRestrictionId?: string) => {
    const addRestrictionAsync = async () => {
      setSaving(true);
      try {
        if (edittedRestrictionId) {
          await AviTracerApi.editAirfieldSlotRestriction(airfieldId, dateStr!, edittedRestrictionId, fromTime, toTime, slotType, remarks);
        }else{
          await AviTracerApi.addAirfieldSlotRestriction(airfieldId, dateStr!, fromTime, toTime, slotType, remarks);
        }
        
        setAddRestrictionModalData(undefined);
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    };
    addRestrictionAsync();
  };

  const editRestrictionPressed = (restrictionId: string) => {
    const restriction = storedSlots.find((s) => s.id === restrictionId);
    if (restriction && restriction.status === "unavailable") {
      setAddRestrictionModalData({
        edittedRestriction: {
          startDateTimeEpoch: restriction.startDateTimeEpoch,
          endDateTimeEpoch: restriction.endDateTimeEpoch,
          reason: restriction.reason!,
          editedRestrictionId: restriction.id,
        },
      });
    }
  };

  const getRowStyle = useCallback(
    (cell: Cell<AirfieldSlotTime, unknown>) => {
      const isNow = moment(cell.row.original.startDateTime).isBetween(
        moment(currentTime).subtract(10, "minutes"),
        moment(currentTime).add(10, "minutes")
      );

      const getWidth = () => {
        if (filteredSlotType) {
          return cell.column.id === "startDateTime" ? "20%" : "80%";
        } else {
          return `${cell.column.columnDef.size}%`;
        }
      };

      return {
        width: getWidth(),
        backgroundColor: isNow ? "#555" : "white",
        color: isNow ? "white" : "black",
      };
    },
    [currentTime, filteredSlotType]
  );

  const flyMatch = useMatch("/fly/airfields/:airfieldId/slots") 
  const inFlyLayout = useMemo(() => {
    return flyMatch !== null
  }, [flyMatch])

  return (
    <Container fluid={!inFlyLayout} className={"vh-100 ".concat((!inFlyLayout) ? "px-0 px-md-2" : "")}>
      <ListEntitiesHeaderComponent
        title={airfield.name}
        actionBtn={ (adminOrgId === selectedOrg?.id) ? {
          title: "Add Restriction",
          onClick: () => {
            setAddRestrictionModalData({});
          },
        } : undefined}
      >
        <FlatPicker
          options={{
            mode: "single",
            maxDate: moment().startOf("day").add(10, "day").toDate(),
            minDate: moment().startOf("day").subtract(10, "day").toDate(),
          }}
          value={date}
          onChange={(dates) => {
            setHasLoadedStoredSlots(false);
            setSearchParams({ date: moment(dates[0]).utc(true).format("DD-MM-YYYY") });
          }}
          render={({ value, onChange }, ref) => (
            <div className="p-3 rounded-lg" style={{ backgroundColor: "#F7F7F7" }}>
              <FontAwesomeIcon icon={faCalendarDays} />
              <input ref={ref} value={""} onChange={(e) => {}} placeholder="Search" className="ps-3 borderless"></input>
            </div>
          )}
        />
      </ListEntitiesHeaderComponent>

      <AirfieldSlotsSelectFilterButtonsComponent
        filteredSlotType={filteredSlotType}
        setFilteredSlotType={setFilteredSlotType}
        availableSlots={availableSlots}
      />
      
      {!hasLoadedStoredSlots ? (
        <LoadingComponent text={"Loading slots..."} />
      ) : (
        <Row className="overflow-auto" style={{ height: "calc(100% - 200px)" }}>
          <Col>
            <Table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          borderTop: "none",
                          position: "sticky",
                          top: "0",
                          zIndex: "1",
                          background: "white",
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td 
                        key={cell.id}
                        style={getRowStyle(cell)}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      <Modal show={bookSlotModalData !== undefined}>
        {bookSlotModalData && (
          <>
            {defaultContactDetails ? (
              <BookSlotModalComponent
                airfield={airfield}
                bookSlotModalData={bookSlotModalData ?? savedSlotModalData!.bookSlotModalData}
                defaultContactDetails={defaultContactDetails!}
                saving={saving}
                savePressed={bookSlotPressed}
                cancelPressed={() => {
                  setBookSlotModalData(undefined);
                  setSavedSlotModalData(undefined);
                }}
                slotsNeedApproval={slotsNeedApproval}
                isAdminOrg={isAdminOrg}
              />
            ) : (
              <AnonymousModalComponent
                closePressed={() => {
                  setBookSlotModalData(undefined);
                }}
                setDefaultContactDetails={setDefaultContactDetails}
                />
            )}
          </>
        )}
      </Modal>
      <Modal show={savedSlotModalData !== undefined}>
        {savedSlotModalData && (
          <BookSlotModalComponent
            airfield={airfield}
            bookSlotModalData={savedSlotModalData.bookSlotModalData}
            defaultContactDetails={defaultContactDetails!}
            saving={saving}
            savePressed={bookSlotPressed}
            cancelPressed={() => {
              setBookSlotModalData(undefined);
              setSavedSlotModalData(undefined);
            }}
            slotsNeedApproval={slotsNeedApproval}
            savedSlotData={savedSlotModalData.savedSlotData}
            isAdminOrg={isAdminOrg}
          />
        )}
      </Modal>
      <Modal show={deleteSlotModalData !== undefined}>
        {deleteSlotModalData && (
          <CancelBookModalComponent
            isRestriction={deleteSlotModalData.slotTime.slots[deleteSlotModalData.slotType].unavailableSlotId !== undefined}
            saving={saving}
            deletePressed={(isRestriction) => {
              if (!isRestriction){
                const storedSlotId = deleteSlotModalData.slotTime.slots[deleteSlotModalData.slotType].selectedOrgRequest?.requestedSlotId || deleteSlotModalData.slotTime.slots[deleteSlotModalData.slotType].bookedBy!.bookedSlotId;
                deleteSlotPressed(storedSlotId);
              }else{
                deleteSlotPressed(deleteSlotModalData.slotTime.slots[deleteSlotModalData.slotType].unavailableSlotId!);
              }
            }}
            cancelPressed={() => {
              setDeleteSlotModalData(undefined);
            }}
          />
        )}
      </Modal>
      <Modal show={rejectSlotModalData !== undefined}>
        {rejectSlotModalData && (
          <RejectSlotModalComponent
            saving={saving}
            rejectPressed={() => {
              rejectRequestedSlotPressed(rejectSlotModalData.requestedSlotId);
            }}
            cancelPressed={() => {
              setRejectSlotModalData(undefined);
            }}
          />
        )}
      </Modal>
      <Modal show={addRestrictionModalData !== undefined}>
        {addRestrictionModalData && (
          <AirfieldSlotsSelectRestrictionModalComponent
            edittedRestriction={addRestrictionModalData.edittedRestriction}
            saving={saving}
            date={date}
            saveRestrictionPressed={saveRestrictionPressed}
            availableSlotTypes={availableSlotTypes}
            cancelPressed={() => {
              setAddRestrictionModalData(undefined);
            }}
          />
        )}
      </Modal>
    </Container>
  );
}

export default AirfieldSlotsSelectPage;


export type BookSlotModalData = {slotTime: AirfieldSlotTime, slotType: AirfieldSlotType};

interface BookSlotModalComponentProps {
  airfield: Airfield
  bookSlotModalData: BookSlotModalData
  defaultContactDetails: AirfieldSlotContactDetails
  saving: boolean
  savePressed: (callsign: string, remarks: string, contactDetails: AirfieldSlotContactDetails) => void
  savedSlotData?: {callsign: string, remarks: string, contactDetails: AirfieldSlotContactDetails}
  cancelPressed: () => void
  slotsNeedApproval: boolean
  isAdminOrg: boolean
}

function BookSlotModalComponent({airfield, bookSlotModalData, defaultContactDetails, saving, savePressed, cancelPressed, slotsNeedApproval, isAdminOrg, savedSlotData}: BookSlotModalComponentProps) {

  const [newCallsign, setNewCallsign] = useState(savedSlotData?.callsign || "")
  const [newRemarks, setNewRemarks] = useState(savedSlotData?.remarks || "")

  const [contactName, setContactName] = useState(savedSlotData?.contactDetails.fullName || defaultContactDetails.fullName)
  const [contactEmail, setContactEmail] = useState(savedSlotData?.contactDetails.email || defaultContactDetails.email)
  const [contactPhone, setContactPhone] = useState(savedSlotData?.contactDetails.phone || defaultContactDetails.phone)
  const [contactOrgName, setContactOrgName] = useState(savedSlotData?.contactDetails.orgName || defaultContactDetails.orgName)

  const [errorsPerField, setErrorsPerField] = useState<{[key: string]: string}>({})

  const validateAndSave = () => {
    const errors = {} as {[key: string]: string}
    if ((!newCallsign) || newCallsign.trim().length === 0) {
      errors["callsign"] = "Callsign is required"
    }
    if ((!contactName) || contactName.trim().length === 0) {
      errors["fullName"] = "Full Name is required"
    }
    if ((!contactEmail || contactEmail.trim().length === 0) && !isAdminOrg) {
      errors["email"] = "Email is required"
    }
    if ((!contactPhone || contactPhone.trim().length === 0) && !isAdminOrg) {
      errors["phone"] = "Phone is required"
    }

    const phoneRegex = new RegExp(/^\+[1-9]\d{1,14}$/)
    if (contactPhone && !phoneRegex.test(contactPhone)) {
      errors["phone"] = "Phone is invalid. Need international format with country code and no spaces. Ex: +306957654321"
    }
    const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    if (contactEmail && !emailRegex.test(contactEmail)) {
      errors["email"] = "Email is invalid"
    }
    if ((!contactOrgName) || contactOrgName.trim().length === 0) {
      errors["orgName"] = "Organization Name is required"
    }
    if (Object.keys(errors).length > 0) {
      setErrorsPerField(errors)
    }else{
      const contactDetails: AirfieldSlotContactDetails = {
        orgName: contactOrgName,
        fullName: contactName,
        phone: (contactPhone && contactPhone.trim().length > 0) ? contactPhone.trim() : undefined,
        email: (contactEmail && contactEmail.trim().length > 0) ? contactEmail.trim() : undefined,
      }
      savePressed(newCallsign, newRemarks, contactDetails)
    }
  }

  const isDisabled = saving || savedSlotData !== undefined

  return (
    <>
      <Modal.Header>
        <Modal.Title>{(slotsNeedApproval && !isAdminOrg) ? "Request" : "Book"} Slot</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="gy-2 gx-2">
          <Col xs={6}>
            <ReadOnlyInputBox
              name={"Date"}
              isDisabled={isDisabled}
              value={moment(bookSlotModalData.slotTime.startDateTime).utc(false).format("DD/MM/YYYY")}
            />
          </Col>
          <Col xs={6}>
            <ReadOnlyInputBox
              name={"Time (UTC)"}
              isDisabled={isDisabled}
              value={moment(bookSlotModalData.slotTime.startDateTime).utc(false).format("HH:mm")}
            />
          </Col>
          <Col xs={12}>
            <ReadOnlyInputBox
              name={"Slot"}
              isDisabled={isDisabled}
              value={airfield.availableSlots.find((s) => s.slotType === bookSlotModalData.slotType)!.name}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={newCallsign}
              isDisabled={isDisabled}
              name="Aircraft Callsign"
              uppercase={true}
              invalidError={errorsPerField["callsign"]}
              onChange={(v) => setNewCallsign(v)}
            />
          </Col>
          <Col xs={12}>
            <TextAreaBox name={"Remarks"} rows={3} isDisabled={isDisabled} value={newRemarks} onChange={(v) => setNewRemarks(v)} />
            <small>
              Please specify if you require fuel, taxi services, a car, overnight stay arrangements, or any other special requests for the
              landing site.
            </small>
          </Col>
          <Col xs={12}>
            <h5 className="pt-3">Contact Details</h5>
          </Col>
          <Col xs={12}>
            <InputBox
              value={contactOrgName}
              isDisabled={isDisabled}
              name="Organization Name or Private"
              invalidError={errorsPerField["orgName"]}
              onChange={(v) => setContactOrgName(v)}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={contactName}
              isDisabled={isDisabled}
              name="Full Name"
              invalidError={errorsPerField["fullName"]}
              onChange={(v) => setContactName(v)}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={contactEmail ?? ""}
              isDisabled={isDisabled}
              name="Email"
              invalidError={errorsPerField["email"]}
              onChange={(v) => setContactEmail(v)}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={contactPhone ?? ""}
              isDisabled={isDisabled}
              name="Phone (International Format)"
              invalidError={errorsPerField["phone"]}
              onChange={(v) => setContactPhone(v)}
            />
          </Col>
          <Col xs={12}>
          <small>SMS and E-mail will be sent when the request is accepted or rejected.</small>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100 mx-0 gx-2 ">
          <Col xs={savedSlotData ? 12 : 3}>
            <Button className="w-100" variant={(savedSlotData) ? "dark" : "light"} disabled={saving} onClick={cancelPressed}>
              {savedSlotData ? "Close" : "Cancel"}
            </Button>
          </Col>
          {!savedSlotData && (
            <Col xs={9}>
              <Button className="w-100" variant="dark" disabled={saving} onClick={validateAndSave}>
                {slotsNeedApproval && !isAdminOrg ? "Request" : "Book"} Slot
              </Button>
            </Col>
          )}
        </Row>
      </Modal.Footer>
    </>
  );

}

interface CancelBookModalComponentProps {
  saving: boolean
  deletePressed: (isRestriction: boolean) => void
  cancelPressed: () => void
  isRestriction: boolean
}

function CancelBookModalComponent({saving, deletePressed, cancelPressed, isRestriction}: CancelBookModalComponentProps) {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Cancel {isRestriction ? "Restriction" : "Slot" }?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to cancel this {isRestriction ? "restriction" : "booking"}?</p>
        <p>Please note that this action is irreversible. You will need to book a new slot if you wish to reschedule.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={saving} onClick={cancelPressed}>
          Keep {isRestriction ? "Restriction" : "Slot" }
        </Button>
        <Button variant="outline-danger" disabled={saving} onClick={() => deletePressed(isRestriction)}>
          Delete {isRestriction ? "Restriction" : "Slot" }
        </Button>
      </Modal.Footer>
    </>
  );
}

interface RejectSlotModalComponentProps {
  saving: boolean
  rejectPressed: () => void
  cancelPressed: () => void
}

function RejectSlotModalComponent({saving, rejectPressed, cancelPressed}: RejectSlotModalComponentProps) {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Reject Request?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to reject this request?</p>
        <p>Please note that this action is irreversible.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" disabled={saving} onClick={rejectPressed}>
          Reject Request
        </Button>
        <Button variant="outline-secondary" disabled={saving} onClick={cancelPressed}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
}

interface AnonymousModalComponentProps {
  setDefaultContactDetails: React.Dispatch<React.SetStateAction<AirfieldSlotContactDetails | undefined>>
  closePressed: () => void
}

function AnonymousModalComponent({setDefaultContactDetails, closePressed}: AnonymousModalComponentProps) {

  const navigate = useNavigate();

  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactOrgName, setContactOrgName] = useState("");

  const [errorsPerField, setErrorsPerField] = useState<{ [key: string]: string }>({});

  const validateAndSave = () => {
    const errors = {} as { [key: string]: string };

    if (!contactName) {
      errors["fullName"] = "Full Name is required";
    }
    if (!contactEmail) {
      errors["email"] = "Email is required";
    }
    if (!contactPhone) {
      errors["phone"] = "Phone is required";
    }
    if (!contactOrgName) {
      errors["orgName"] = "Organization Name is required";
    }
    if (Object.keys(errors).length > 0) {
      setErrorsPerField(errors);
    } else {
      const contactDetails: AirfieldSlotContactDetails = {
        email: contactEmail,
        fullName: contactName,
        phone: contactPhone,
        orgName: contactOrgName,
      };
      setDefaultContactDetails(contactDetails);
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Missing Contact Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <p>You are currently not logged in your BRIEF account.</p>

      You may log in to your account, or proceed in Guest mode by completing the form below.

        <div className="pt-4 d-flex justify-content-center">
        <Button
          variant="outline-dark"
          className="w-50"
          onClick={() => {
            navigate("/auth/logout?redirectTo=/airfields/lgmg/slots");
          }}
        >
          Login
        </Button>
        </div>
        <hr/>
        <Row className="gy-2 gx-2">
          <Col xs={12}>
            <h5 className="pt-1">Contact Details</h5>
          </Col>
          <Col xs={12}>
            <InputBox
              value={contactOrgName}
              isDisabled={false}
              name="Organization Name or Private"
              invalidError={errorsPerField["orgName"]}
              onChange={(v) => setContactOrgName(v)}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={contactName}
              isDisabled={false}
              name="Full Name"
              invalidError={errorsPerField["fullName"]}
              onChange={(v) => setContactName(v)}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={contactEmail}
              isDisabled={false}
              name="Email"
              invalidError={errorsPerField["email"]}
              onChange={(v) => setContactEmail(v)}
            />
          </Col>
          <Col xs={12}>
            <InputBox
              value={contactPhone}
              isDisabled={false}
              name="Phone"
              invalidError={errorsPerField["phone"]}
              onChange={(v) => setContactPhone(v)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100 mx-0 gx-2 ">
          <Col xs={3}>
            <Button className="w-100" variant="light" onClick={closePressed}>
              Cancel
            </Button>
          </Col>
          <Col xs={9}>
            <Button className="w-100" variant="dark" onClick={validateAndSave}>
              Continue
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </>
  );
}