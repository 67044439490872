import { CellContext } from "@tanstack/react-table";
import { Organization } from "../../models/organization";
import { Airfield, AirfieldSlot, AirfieldSlotContactDetails, AirfieldSlotTime, AirfieldSlotType } from "../../models/airfieldSlots";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Alert, Button, Col } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BookSlotModalData } from "./AirfieldSlotsSelectPage";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface AirfieldSlotsSelectCellProps {
    airfield: Airfield;
    selectedOrg: Organization;
    isAdminOrg: boolean;
    info: CellContext<AirfieldSlotTime, AirfieldSlot>;
    setBookSlotModalData: React.Dispatch<React.SetStateAction<BookSlotModalData | undefined>>;
    setSavedSlotModalData: React.Dispatch<React.SetStateAction<{bookSlotModalData: BookSlotModalData, savedSlotData: {callsign: string, remarks: string, contactDetails: AirfieldSlotContactDetails}} | undefined>>;
    slotType: AirfieldSlotType;
    slotName: string;
    setDeleteSlotModalData: React.Dispatch<React.SetStateAction<BookSlotModalData | undefined>>;
    setRejectSlotModalData: React.Dispatch<React.SetStateAction<{requestedSlotId: string} | undefined>>;
    setTimeForBookedSlotPressed: (bookedSlotId: string, timeType: "blocksOff" | "takeoff" | "landed") => void;
    editRestrictionPressed: (restrictionId: string) => void;
    bookRequestedSlotPressed: (requestedSlotId: string) => void;
    slotsNeedApproval: boolean;
    slotsArePrivate: boolean;
    timeTypesToLog: ("blocksOff" | "takeoff" | "landed")[]
  }
  
  function AirfieldSlotsSelectCell({ airfield, selectedOrg, isAdminOrg, info, setBookSlotModalData, setSavedSlotModalData, slotType, slotName, setDeleteSlotModalData, setRejectSlotModalData, setTimeForBookedSlotPressed, editRestrictionPressed, slotsNeedApproval, slotsArePrivate, bookRequestedSlotPressed, timeTypesToLog }: AirfieldSlotsSelectCellProps) {
    const slot = info.cell.getValue();
  
    const navigate = useNavigate();
  
    const [inlineSaving, setInlineSaving] = useState(false);
  
    if (slot.pendingConfirmation) {
      return (
        <Alert variant="secondary" className="d-flex justify-content-between align-items-center mb-0" style={{ height: "120px" }}>
          <strong>Pending Confirmation</strong>
        </Alert>
      );
    } else if (slot.onHoldUntil) {
      return (
        <Alert variant="secondary" className="d-flex justify-content-between align-items-center mb-0" style={{ height: "120px" }}>
          <div>
            On Hold Until <strong>{moment(slot.onHoldUntil).utc(false).format("HH:mm")}Z</strong>
          </div>
        </Alert>
      );
    } else if (slot.selectedOrgRequest) {
      return (
        <Alert
          variant="warning"
          className="d-flex justify-content-between align-items-center mb-0 p-sm-2 p-xxl-3 py-2"
          style={{ height: "120px" }}
        >
          <div className="d-flex flex-column w-100">
            <div className="py-1 d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div>
                  Requested for <strong>{slot.selectedOrgRequest.callsign}</strong>
                </div>
                {slot.selectedOrgRequest?.remarks && <small>{slot.selectedOrgRequest?.remarks}</small>}
              </div>
              <div className="d-flex flex-column ">
                
                <SlotDetailsButton
                  inlineSaving={inlineSaving}
                  setSavedSlotModalData={setSavedSlotModalData}
                  slotType={slotType}
                  slot={slot}
                  slotTime={info.row.original}
                  savedSlotData={slot.selectedOrgRequest}
                />
                <DeleteSlotButton setDeleteSlotModalData={setDeleteSlotModalData} info={info} slotType={slotType} />
              </div>
            </div>
          </div>
        </Alert>
      );
    } else if (slot.allRequests) {
      return (
        <Alert
          variant={"warning"}
          className="d-flex justify-content-between align-items-center mb-0 p-sm-2 p-xxl-3 py-2"
          style={{ minHeight: "120px" }}
        >
          <div className="d-flex flex-column w-100">
            {slot.allRequests.map((request, index) => (
              <div key={request.orgId}>
                {index > 0 && <hr />}
                <div key={request.orgId} className="py-1 d-flex justify-content-between">
                  <div className="d-flex flex-column justify-content-center">
                    <div>
                      <strong>{request.callsign}</strong> ({request.contactDetails.orgName}){" "}
                    </div>
                    {request.remarks && <small>{request.remarks}</small>}
                  </div>
                  <div className="d-flex flex-column">
                    <Button
                      variant="success"
                      disabled={inlineSaving}
                      className="m-1 w-100"
                      size="sm"
                      onClick={() => {
                        if (slot.allRequests!.length > 1) {
                          alert("Please Reject and coordinate the other requests first, before finally accepting this one.");
                          return
                        }
                        setInlineSaving(true);
                        bookRequestedSlotPressed(request.requestedSlotId);
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="danger"
                      disabled={inlineSaving}
                      className="m-1 w-100"
                      size="sm"
                      onClick={() => {
                        setRejectSlotModalData({ requestedSlotId: request.requestedSlotId });
                      }}
                    >
                      Reject
                    </Button>
                    <SlotDetailsButton
                      inlineSaving={inlineSaving}
                      setSavedSlotModalData={setSavedSlotModalData}
                      slotType={slotType}
                      slot={slot}
                      slotTime={info.row.original}
                      savedSlotData={request}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Alert>
      );
    } else if (slot.bookedBy) {
      const hideBookInfo = slotsArePrivate && slot.bookedBy.orgId !== selectedOrg.id && !isAdminOrg
      return (
        <Alert
          variant={(slot.bookedBy.orgId === selectedOrg.id || isAdminOrg) ? "success" : "warning"}
          className="d-flex justify-content-between align-items-center p-sm-2 p-xxl-3 mb-0 py-2"
          style={{ height: "120px" }}
        >
          <div className="d-flex flex-column w-100">
            <div className="py-1 d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                {hideBookInfo ? (
                  <strong>Booked</strong>
                ) : (
                  <div className="d-flex flex-column">
                    <div>
                      <strong>{slot.bookedBy!.callsign}</strong> ({slot.bookedBy!.contactDetails.orgName}){" "}
                    </div>
                    {slot.bookedBy!.remarks && <small>{slot.bookedBy!.remarks}</small>}
                  </div>
                )}
              </div>
              <div className="d-flex flex-column ">
                {slot.bookedBy.orgId === selectedOrg.id && !isAdminOrg && airfield.enableFlightLink && (
                  <>
                    {slot.bookedBy!.flightId ? (
                      <Button
                        className="m-1 w-100 h-100"
                        size="sm"
                        onClick={() => {
                          if (selectedOrg.role === "admin") {
                            navigate("/flights/" + slot.bookedBy!.flightId);
                          } else {
                            navigate("/fly/" + slot.bookedBy!.flightId);
                          }
                        }}
                      >
                        Go to Flight
                      </Button>
                    ) : (
                      <>
                        {selectedOrg.role === "admin" && (
                          <Button
                            className="m-1 w-100 h-100"
                            size="sm"
                            onClick={() => {
                              navigate(
                                `/flights/add?depAirfieldId=${airfield.id}&departureDateTime=${
                                  info.row.original.startDateTime.getTime() / 1000
                                }&slotType=${slotType}&bookedSlotKey=${slot.bookedBy!.bookedSlotId}`
                              );
                            }}
                          >
                            Create
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}

                {isAdminOrg && (
                  <BookedSlotLogTimesComponent
                    slot={slot}
                    inlineSaving={inlineSaving}
                    setInlineSaving={setInlineSaving}
                    setTimeForBookedSlotPressed={setTimeForBookedSlotPressed}
                    timeTypesToLog={timeTypesToLog}
                  />
                )}

                {!hideBookInfo && (
                  <SlotDetailsButton
                    inlineSaving={inlineSaving}
                    setSavedSlotModalData={setSavedSlotModalData}
                    slotType={slotType}
                    slot={slot}
                    slotTime={info.row.original}
                    savedSlotData={slot.bookedBy}
                  />
                )}

                {(slot.bookedBy.orgId === selectedOrg.id || isAdminOrg) && (
                  <DeleteSlotButton setDeleteSlotModalData={setDeleteSlotModalData} info={info} slotType={slotType} />
                )}
              </div>
            </div>
          </div>
        </Alert>
      );
    } else if (slot.available) {
      return (
        <Alert variant="primary" className="d-flex justify-content-between align-items-center mb-0" style={{ height: "120px" }}>
          <div className="py-1 d-flex justify-content-center w-100">
            <Button
              className="w-50"
              onClick={() => {
                setBookSlotModalData({ slotTime: info.row.original, slotType });
              }}
            >
              {slotsNeedApproval && !isAdminOrg ? "Request" : "Book"} Slot
            </Button>
          </div>
        </Alert>
      );
    } else if (!slot.available) {
      return (
        <Alert
          variant={slot.unavailableReason ? "danger" : "light"}
          className="d-flex justify-content-between align-items-center mb-0"
          style={{ height: "120px" }}
        >
          <div>{slot.unavailableReason ? <strong>{slot.unavailableReason}</strong> : <>Not available</>}</div>
          {isAdminOrg && (
            <>
              <Button
                className="w-50"
                onClick={() => {
                  setBookSlotModalData({ slotTime: info.row.original, slotType });
                }}
              >
                Override
              </Button>
              {info.row.original.slots[slotType].unavailableSlotId && slotType !== "private" && (
                <div className="d-flex flex-column">
                  <Button
                    size="sm"
                    className="m-1 w-100"
                    variant="secondary"
                    onClick={() => {
                      editRestrictionPressed(info.row.original.slots[slotType].unavailableSlotId!);
                    }}
                  >
                    Update
                  </Button>
                  <DeleteSlotButton setDeleteSlotModalData={setDeleteSlotModalData} info={info} slotType={slotType} />
                </div>
              )}
            </>
          )}
        </Alert>
      );
    }
    return <></>
  }
  
export default AirfieldSlotsSelectCell;

interface SlotDetailsButtonProps {
    inlineSaving: boolean;
    setSavedSlotModalData: React.Dispatch<React.SetStateAction<{bookSlotModalData: BookSlotModalData, savedSlotData: {callsign: string, remarks: string, contactDetails: AirfieldSlotContactDetails}} | undefined>>;
    slotType: AirfieldSlotType;
    slot: AirfieldSlot;
    slotTime: AirfieldSlotTime;
    savedSlotData: {callsign: string, remarks: string, contactDetails: AirfieldSlotContactDetails};
}

function SlotDetailsButton({ inlineSaving, setSavedSlotModalData, slotType, slot, savedSlotData, slotTime }: SlotDetailsButtonProps) {

    return (
        <Button
        variant="secondary"
        className="m-1 w-100"
        size="sm"
        disabled={inlineSaving}
        onClick={() => {
          setSavedSlotModalData({
            bookSlotModalData: {
              slotTime: slotTime,
              slotType: slotType,
            },
            savedSlotData: savedSlotData,
          });
        }}
      >
        Details
      </Button>
    )
}

interface DeleteSlotButtonProps {
    setDeleteSlotModalData: React.Dispatch<React.SetStateAction<BookSlotModalData | undefined>>;
    info: CellContext<AirfieldSlotTime, AirfieldSlot>;
    slotType: AirfieldSlotType;
}

function DeleteSlotButton({ setDeleteSlotModalData, info, slotType }: DeleteSlotButtonProps) {
  return (
    <Button
      variant="danger"
      className="m-1 w-100"
      size="sm"
      onClick={() => {
        setDeleteSlotModalData({ slotTime: info.row.original, slotType });
      }}
    >
      <FontAwesomeIcon icon={faTrash} color="white" />
    </Button>
  );
}

interface BookedSlotLogTimesComponentProps {
    slot: AirfieldSlot;
    inlineSaving: boolean;
    setInlineSaving: React.Dispatch<React.SetStateAction<boolean>>;
    setTimeForBookedSlotPressed: (bookedSlotId: string, timeType: "blocksOff" | "takeoff" | "landed") => void;
    timeTypesToLog: ("blocksOff" | "takeoff" | "landed")[];
}

function BookedSlotLogTimesComponent({ slot, inlineSaving, setInlineSaving, setTimeForBookedSlotPressed, timeTypesToLog }: BookedSlotLogTimesComponentProps) {
    return (
        <>
        {timeTypesToLog.includes("landed") && (
          <Col className="d-flex flex-column align-items-center justify-content-center">
            <div>Land:</div>
            {slot.landedEpoch ? (
              <strong>
                {moment(slot.landedEpoch * 1000)
                  .utc(false)
                  .format("HH:mm")}
              </strong>
            ) : (
              <Button
                className="h-100"
                size="sm"
                disabled={inlineSaving}
                onClick={() => {
                  setInlineSaving(true);
                  setTimeForBookedSlotPressed(slot.bookedBy!.bookedSlotId, "landed");
                }}
              >
                Set
              </Button>
            )}
          </Col>
        )}
        {timeTypesToLog.includes("blocksOff") && (
          <Col className="d-flex flex-column align-items-center justify-content-center">
            <div>Start:</div>
            {slot.blocksOffEpoch ? (
              <strong>
                {moment(slot.blocksOffEpoch * 1000)
                  .utc(false)
                  .format("HH:mm")}
              </strong>
            ) : (
              <Button
                className="h-100"
                size="sm"
                disabled={inlineSaving}
                onClick={() => {
                  setInlineSaving(true);
                  setTimeForBookedSlotPressed(slot.bookedBy!.bookedSlotId, "blocksOff");
                }}
              >
                Set
              </Button>
            )}
          </Col>
        )}
        {timeTypesToLog.includes("takeoff") && (
          <Col className="d-flex flex-column align-items-center justify-content-center">
            <div>T/O:</div>
            {slot.takeoffEpoch ? (
              <strong>
                {moment(slot.takeoffEpoch * 1000)
                  .utc(false)
                  .format("HH:mm")}
              </strong>
            ) : (
              <Button
                className="h-100"
                size="sm"
                disabled={inlineSaving}
                onClick={() => {
                  setInlineSaving(true);
                  setTimeForBookedSlotPressed(slot.bookedBy!.bookedSlotId, "takeoff");
                }}
              >
                Set
              </Button>
            )}
          </Col>
        )}
      </>
    )
}